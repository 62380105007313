import './styles/BurguerButton.css';
import React from 'react';
import articles from './articles';
import {scrollToSection} from './utils';

const HamburgerButton = () => {
  const [clicked, setClicked] = React.useState(false);

  return (
    <div className="hamburgerButton__box">
      <div className="hamburgerButton" onClick={() => setClicked(true)}>
        <div className={`hamburgerButton__line ${clicked ? 'hamburgerButton__line__clicked' : ''}`}></div>
        <div className={`hamburgerButton__line ${clicked ? 'hamburgerButton__line__clicked' : ''}`}></div>
        <div className={`hamburgerButton__line ${clicked ? 'hamburgerButton__line__clicked' : ''}`}></div>
      </div>
      {clicked && (
        <div className="hamburgerButton__menu--shadow" onClick={() => {setClicked(false)}}></div>
      )}
      <aside className={`hamburgerButton__menu ${clicked ? 'hamburgerButton__menu--clicked' : ''}`}>
        <ul className="hamburgerButton__menu__list">
          {
            articles.map(({linkLabel, id}, index) => (
              <li key={`linkLabel--${index}`} className="hamburgerButton__menu__list__item" onClick={() => {
                setClicked(false)
                scrollToSection(id)
              }}>
                <p>{linkLabel}</p>
              </li>
            ))
          }
        </ul>
      </aside>
    </div>
  )
}

export default HamburgerButton;
