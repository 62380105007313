import {Articles} from "./index";
import './styles/Main.css';

const Main = () => {
  return (
    <main className='app__main'>
      <section className="articlesSection__main">
        <Articles/>
      </section>
    </main>
  )
}

export default Main;
