import './styles/Articles.css';
import {AboutUs} from './sectionsMain/AboutUs';
import {Values} from './sectionsMain/Values';
import {Clients} from './sectionsMain/Clients';
import {Services} from './sectionsMain/Services';
import {Technologies} from './sectionsMain/Technologies';

const Articles = () => {
  return (
    <>
      <AboutUs/>
      <Values/>
      <Clients/>
      <Services/>
      <Technologies/>
    </>
  );
};

export default Articles;
