import techs from '../../assets/articles/techs.png';

export const Technologies = () => {
  return (
    <>
      <article className='textCard cuidadosParaVoceCard'>
        <h2 className="textCard__title">Nós cuidamos para você.</h2>
        <div className="textCard__contents">
          <p className="textCard__paragraph">Toda a infraestrutura necessária para que você possa focar no que
            realmente
            importa: o seu negócio. O sistema deve trabalhar para você, e não o contrário. Nós cuidamos para que tudo
            esteja sempre funcionando perfeitamente, para que você possa se dedicar ao que realmente importa.</p>
          <p className="textCard__paragraph">Usamos computação em nuvem para garantir que seus dados estejam sempre
            seguros e acessíveis, e que suas aplicações estejam sempre disponíveis e funcionando perfeitamente.</p>
        </div>
      </article>
      <article className="textCard tecnologiasCard" id="tecnologias">
        <h2 className="textCard__title">Tecnologias</h2>
        <div className="textCard__contents">
          <p className="textCard__paragraph">Nós utilizamos as tecnologias mais modernas e poderosas para criar soluções
            personalizadas e inovadoras que atendam às necessidades de nossos clientes e parceiros, ajudando-os a
            alcançar seus objetivos e superar desafios.</p>
          <p className="textCard__paragraph">Pensando em clientes que precisam de prazo em paralelo com a qualidade, já
            possuímos modelos de negócio já prontos para atender a sua necessidade.</p>
        </div>
        <figure className="textImageCard__image">
          <img src={techs} alt="Tecnologias" style={{marginTop: '30px'}}/>
          <figcaption>Imagem de "Tecnologias"</figcaption>
        </figure>
      </article>
    </>
  );
};
