export const Services = () => {
  return (
    <article className="textCard" id="servicos">
      <h2 className="textCard__title">Serviços</h2>
      <div className="textCard__contents">
        <p className="textCard__paragraph">Nós acreditamos que a tecnologia é uma ferramenta poderosa para transformar
          ideias em realidade e impulsionar um futuro conectado, sustentável e cheio de possibilidades. Por isso,
          oferecemos soluções personalizadas e inovadoras para atender às necessidades de nossos clientes e parceiros,
          ajudando-os a alcançar seus objetivos e superar desafios.</p>
        <div className='servicos'>
          <div className='textCard__servicos--itens'>
            <p className="textCard__paragraph servicos__item">
              <svg width="40px" height="40px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.5 14.5V16.5C4.5 17.0523 4.94772 17.5 5.5 17.5H12.5M4.5 14.5V6.5C4.5 5.94772 4.94772 5.5 5.5 5.5H19.5C20.0523 5.5 20.5 5.94772 20.5 6.5V14.5M4.5 14.5H20.5M20.5 14.5V16.5C20.5 17.0523 20.0523 17.5 19.5 17.5H12.5M12.5 17.5V20.5M12.5 20.5H8M12.5 20.5H17"
                  stroke="#fff" stroke-width="1.2"/>
              </svg>
              Sites
            </p>
            <p className="textCard__paragraph servicos__item">
              <svg width="40px" height="40px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.5" y="3" width="12" height="18" rx="3" stroke="#fff" stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                  d="M12.5 18.5C12.3665 18.5 12.2409 18.448 12.1465 18.3536C12.052 18.2591 12 18.1336 12 18C12 17.9008 12.0291 17.8047 12.0843 17.7222C12.1394 17.6397 12.217 17.576 12.3086 17.5381C12.3696 17.5128 12.434 17.5 12.5 17.5C12.5327 17.5 12.5655 17.5032 12.5975 17.5096C12.6949 17.529 12.7834 17.5763 12.8536 17.6464C12.9237 17.7166 12.971 17.8051 12.9904 17.9025C13.0098 17.9998 12.9999 18.0997 12.9619 18.1913C12.924 18.283 12.8603 18.3606 12.7778 18.4157C12.6953 18.4709 12.5992 18.5 12.5 18.5Z"
                  fill="#fff"/>
                <path
                  d="M12.5 19C12.2348 19 11.9804 18.8946 11.7929 18.7071C11.6054 18.5196 11.5 18.2652 11.5 18C11.5 17.8022 11.5586 17.6089 11.6685 17.4444C11.7784 17.28 11.9346 17.1518 12.1173 17.0761C12.3 17.0004 12.5011 16.9806 12.6951 17.0192C12.8891 17.0578 13.0673 17.153 13.2071 17.2929C13.347 17.4327 13.4422 17.6109 13.4808 17.8049C13.5194 17.9989 13.4996 18.2 13.4239 18.3827C13.3482 18.5654 13.22 18.7216 13.0556 18.8315C12.8911 18.9414 12.6978 19 12.5 19Z"
                  fill="#fff"/>
              </svg>
              Aplicativos
            </p>
          </div>
          <div className='textCard__servicos--itens'>
            <p className="textCard__paragraph servicos__item">
              <svg fill="#fff" height="40px" width="40px"
                   viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M235.082,392.745c-5.771,0-10.449,4.678-10.449,10.449v4.678c0,5.771,4.678,10.449,10.449,10.449
			c5.77,0,10.449-4.678,10.449-10.449v-4.678C245.531,397.423,240.853,392.745,235.082,392.745z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M492.948,313.357l-31.393-25.855c1.58-10.4,2.38-20.968,2.38-31.502c0-10.534-0.8-21.104-2.381-31.504l31.394-25.856
			c10.032-8.262,12.595-22.42,6.099-33.66L456.35,91.029c-4.704-8.173-13.479-13.25-22.903-13.25c-3.19,0-6.326,0.573-9.302,1.695
			l-38.109,14.274c-16.546-13.286-34.848-23.869-54.55-31.54l-6.683-40.082C322.676,9.306,311.701,0,298.704,0h-85.408
			C200.3,0,189.324,9.307,187.2,22.119l-6.684,40.088c-19.703,7.673-38.007,18.255-54.553,31.542L87.898,79.492
			c-2.999-1.138-6.14-1.715-9.338-1.715c-9.414,0-18.191,5.074-22.903,13.241l-42.702,73.96
			c-6.499,11.244-3.935,25.403,6.097,33.664l31.394,25.855c-1.58,10.4-2.38,20.969-2.38,31.503c0,10.534,0.8,21.103,2.38,31.503
			l-31.394,25.856c-10.032,8.262-12.595,22.42-6.099,33.66l42.703,73.963c4.716,8.171,13.492,13.247,22.904,13.247
			c3.205,0,6.352-0.581,9.294-1.703l38.107-14.275c16.547,13.287,34.85,23.87,54.551,31.541l6.682,40.075
			C189.316,502.692,200.293,512,213.297,512h85.408c12.991,0,23.967-9.304,26.096-22.118l6.683-40.089
			c19.705-7.673,38.008-18.255,54.554-31.542l38.07,14.261c2.999,1.137,6.141,1.713,9.336,1.713c9.411,0,18.185-5.074,22.9-13.241
			l42.703-73.962C505.543,335.776,502.979,321.619,492.948,313.357z M298.704,491.102H245.53v-49.427
			c0-5.771-4.678-10.449-10.449-10.449c-5.771,0-10.449,4.678-10.449,10.449v49.427h-10.922V376.504
			c13.606,4.844,28.061,7.375,42.865,7.382c0.003,0,0.066,0,0.07,0c14.852,0,29.325-2.528,42.928-7.376v114.515h0.001
			C299.289,491.069,299,491.102,298.704,491.102z M256.642,362.988h-0.057c-58.964-0.029-106.933-48.026-106.932-106.99
			c0.001-34.314,16.175-65.814,43.158-85.745v76.229c0,3.671,1.926,7.072,5.073,8.96l53.381,32.027
			c3.309,1.984,7.443,1.984,10.752,0l53.381-32.027c3.147-1.889,5.073-5.29,5.073-8.96v-76.229
			c26.983,19.931,43.159,51.432,43.157,85.747c0,28.528-11.143,55.382-31.374,75.614
			C312.022,351.846,285.169,362.988,256.642,362.988z M480.949,336.57l-42.705,73.965c-1.326,2.296-4.122,3.423-6.769,2.42
			l-43.772-16.397c-3.557-1.331-7.555-0.63-10.444,1.834c-16.925,14.428-36.026,25.589-56.79,33.212v-64.779
			c9.585-5.551,18.513-12.386,26.56-20.434c24.179-24.18,37.495-56.281,37.495-90.391c0.001-48.242-26.729-91.831-69.76-113.754
			c-3.239-1.651-7.103-1.498-10.203,0.401c-3.099,1.9-4.989,5.274-4.989,8.909v89.011l-42.932,25.759l-42.932-25.759v-89.011
			c0-3.635-1.89-7.009-4.989-8.909c-3.099-1.899-6.963-2.05-10.203-0.401c-43.03,21.922-69.76,65.51-69.762,113.752
			c-0.001,34.743,13.583,67.154,38.247,91.26c7.858,7.68,16.53,14.23,25.809,19.585v65.235
			c-21.258-7.63-40.795-18.958-58.071-33.684c-1.922-1.638-4.333-2.497-6.78-2.497c-1.232,0-2.473,0.217-3.663,0.664l-43.83,16.419
			c-0.613,0.234-1.255,0.353-1.905,0.353c-1.969,0-3.81-1.071-4.805-2.796l-42.706-73.968c-1.365-2.361-0.822-5.337,1.288-7.076
			L68.389,299.8c2.926-2.411,4.318-6.216,3.635-9.944c-2.03-11.12-3.061-22.509-3.061-33.856c0-11.346,1.03-22.736,3.063-33.854
			c0.681-3.729-0.709-7.535-3.636-9.944l-36.051-29.691c-2.112-1.74-2.654-4.716-1.287-7.08l42.705-73.966
			c1.323-2.294,4.109-3.429,6.769-2.419l43.772,16.396c3.555,1.33,7.554,0.63,10.444-1.833
			c17.417-14.847,37.129-26.244,58.589-33.876c3.576-1.272,6.182-4.382,6.805-8.126l7.679-46.059
			c0.446-2.694,2.752-4.649,5.482-4.649h85.408c2.73,0,5.036,1.955,5.485,4.656l7.679,46.053c0.624,3.744,3.23,6.856,6.806,8.126
			c21.459,7.631,41.17,19.027,58.586,33.874c2.89,2.463,6.888,3.165,10.444,1.833l43.794-16.405c0.631-0.238,1.287-0.358,1.95-0.358
			c1.97,0,3.805,1.064,4.798,2.789l42.706,73.967c1.365,2.361,0.822,5.337-1.288,7.076l-36.052,29.692
			c-2.926,2.411-4.318,6.215-3.635,9.944c2.03,11.118,3.061,22.509,3.061,33.855c0,11.346-1.03,22.735-3.063,33.853
			c-0.681,3.728,0.709,7.535,3.636,9.944l36.051,29.691C481.774,331.227,482.316,334.205,480.949,336.57z"/>
                  </g>
                </g>
              </svg>
              Sistemas
            </p>
            <p className="textCard__paragraph servicos__item">
              <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 5.25C2 3.45507 3.45507 2 5.25 2H16.75C18.5449 2 20 3.45507 20 5.25V8.00934C19.4951 7.95675 18.9878 7.99076 18.5 8.10566V7H3.5V16.75C3.5 17.7165 4.2835 18.5 5.25 18.5H13.2347L12.7935 19.2641C12.6577 19.4994 12.5652 19.7477 12.5135 20H5.25C3.45507 20 2 18.5449 2 16.75V5.25ZM5.25 3.5C4.2835 3.5 3.5 4.2835 3.5 5.25V5.5H18.5V5.25C18.5 4.2835 17.7165 3.5 16.75 3.5H5.25Z"
                  fill="#fff"/>
                <path
                  d="M19.8565 9C19.6411 8.98005 19.4255 8.9803 19.2123 9C18.1194 9.10098 17.0907 9.71296 16.5002 10.7357C15.7915 11.9632 15.9109 13.4415 16.685 14.5239L13.6595 19.7641C13.6153 19.8407 13.5788 19.9196 13.5499 20C13.3149 20.6516 13.5724 21.3977 14.1933 21.7562C14.8908 22.1589 15.7828 21.9199 16.1855 21.2224L19.2166 15.9724C20.5321 16.0903 21.8586 15.4548 22.5624 14.2357C23.3244 12.916 23.1291 11.3063 22.1921 10.2105L20.5417 13.0691C20.2195 13.6271 19.506 13.8183 18.948 13.4961C18.39 13.1739 18.1988 12.4604 18.521 11.9024L20.1714 9.04377C20.0667 9.0243 19.9616 9.00974 19.8565 9Z"
                  fill="#fff"/>
                <path
                  d="M15.1652 13.5745C14.9952 12.8933 14.9834 12.1718 15.1463 11.4698L12.8579 9.17831C12.6628 8.98291 12.3462 8.98269 12.1508 9.17782C11.9554 9.37295 11.9552 9.68953 12.1503 9.88493L14.7934 12.5316L12.1503 15.1783C11.9552 15.3737 11.9554 15.6903 12.1508 15.8854C12.3462 16.0805 12.6628 16.0803 12.8579 15.8849L15.1652 13.5745Z"
                  fill="#fff"/>
                <path
                  d="M9.85672 9.88537C10.0521 9.69021 10.0523 9.37363 9.85711 9.17826C9.66196 8.98289 9.34538 8.98271 9.15001 9.17787L6.14664 12.1779C6.05275 12.2717 6 12.3989 6 12.5316C6 12.6643 6.05276 12.7916 6.14665 12.8854L9.15001 15.8854C9.34538 16.0805 9.66196 16.0803 9.85711 15.885C10.0523 15.6896 10.0521 15.373 9.85672 15.1779L7.2075 12.5316L9.85672 9.88537Z"
                  fill="#fff"/>
              </svg>
              Consultoria
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};
