import notebookDarkness from '../../assets/articles/notebook-darkness.png';

export const AboutUs = () => {
  return (
    <article className="textImageCard" id="quem-somos">
      <div className="textImageCard__text">
        <h2 className="textImageCard__title">Nossa Essência</h2>
        <p className="textImageCard__paragraph">Somos uma empresa movida pela paixão em utilizar a tecnologia de ponta
          para criar soluções poderosas e ágeis, transformando ideias em realidade e impulsionando um futuro
          conectado, sustentável e cheio de possibilidades.</p>
      </div>
      <figure className="textImageCard__image">
        <img src={notebookDarkness} alt="Nossa Essência"/>
        <figcaption>Imagem de "Nossa Essência"</figcaption>
      </figure>
    </article>
  );
};
