import './styles/MobileActions.scss';
import logo from "../assets/logo512.png";
import {animateScroll as scroll} from "react-scroll";

export const MobileActions = () => {
  const scrollToSection = (sectionName) => {
    scroll.scrollTo(document.getElementById(sectionName).offsetTop - 10, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  
  return (
    <div className="bottom-appbar">
      <div className="tabs">
        <div className="tab tab--left" onClick={() => {
          scrollToSection('servicos')
        }}>
          <span>Serviços</span>
        </div>
        <div className="tab tab--fab">
          <div className="top">
            <div className="fab">
              <figure className='fab__logo'>
                <img src={logo} alt="wardie-logo"/>
                <figcaption>logo</figcaption>
                <div className="fab__logoLight"></div>
              </figure>
            </div>
          </div>
        </div>
        <div className="tab tab--right" onClick={() => {
          scrollToSection('contato')
        }}>
          <span>Contato</span>
        </div>
      </div>
    </div>
  )
}