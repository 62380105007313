import tdcLogo from '../../assets/clients/tdc-logo.png';
import justinGelbandLogo from '../../assets/clients/justin-gelband-logo.png';
import smtCmmsLogo from '../../assets/clients/smt-cmms-logo.png';
import tsuruLogo from '../../assets/clients/tsuru-logo.png';
import edipediaLogo from '../../assets/clients/edipedia-logo.png';
import lerOMundoLogo from '../../assets/clients/ler-o-mundo-logo.png';

export const Clients = () => {
  return (
    <article className="listCard" id="clientes">
      <header className="listCard__header">
        <h2>Clientes pelo mundo</h2>
        <p>Conheça nossos clientes e parceiros que confiam em nossos serviços e soluções para impulsionar seus
          negócios e transformar suas ideias em realidade.</p>
      </header>
      <ul className="listCard__listContents">
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={tdcLogo} alt="Técnicos de Celular"/>
              <div className="figureShadow" style={{background: 'radial-gradient(#00b21355, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>Técnicos de Celular</p>
            </figcaption>
          </figure>
        </li>
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={justinGelbandLogo} alt="Justin Gelband" style={{maxWidth: 60}}/>
              <div className="figureShadow" style={{background: 'radial-gradient(#fd200f55, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>Justin Gelband</p>
            </figcaption>
          </figure>
        </li>
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={smtCmmsLogo} alt="SMTCMMS"/>
              <div className="figureShadow" style={{background: 'radial-gradient(#00254e, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>SMTCMMS</p>
            </figcaption>
          </figure>
        </li>
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={tsuruLogo} alt="Coletivo Tsuru"/>
              <div className="figureShadow" style={{background: 'radial-gradient(#FF000055, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>Coletivo Tsuru</p>
            </figcaption>
          </figure>
        </li>
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={edipediaLogo} alt="Edipedia"/>
              <div className="figureShadow" style={{background: 'radial-gradient(#07c0bb55, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>Edipedia</p>
            </figcaption>
          </figure>
        </li>
        <li className="listCard__listItem">
          <figure className="listCard__figure">
            <div>
              <img src={lerOMundoLogo} alt="Ler o Mundo"/>
              <div className="figureShadow" style={{background: 'radial-gradient(#FFD40054, transparent 60%)'}}></div>
            </div>
            <figcaption>
              <p>Ler o Mundo</p>
            </figcaption>
          </figure>
        </li>
      </ul>
    </article>
  );
};
