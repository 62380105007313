import {MobileActions} from './MobileActions';
import './styles/Header.css';
import HamburgerButton from './BurguerButton';
import articles from './articles';
import {scrollToSection} from './utils';

const Header = () => {
  const {innerWidth: width} = window;
  const isMobile = width <= 768;

  return (
    <>
      <header className="mainHeader">
        <h1 className="mainHeader__title">Wardie</h1>
        <p className="mainHeader__subtitle">Perfeccionismo e prazo.</p>
      </header>
      {
        isMobile ? <HamburgerButton/> :
          <nav className='desktopNav'>
            <ul className='desktopNav__list'>
              {
                articles.map(({linkLabel, id}, index) => {
                  return (
                    <li
                      className='desktopNav__item'
                      key={`aside-menu-link-${index}`}
                      onClick={() => {
                      scrollToSection(id);
                    }}>
                      <p className='desktopNav__item--label'>{linkLabel}</p>
                    </li>
                  );
                })
              }
            </ul>
          </nav>
      }
      {
        isMobile && <MobileActions/>
      }
    </>
  );
};

export default Header;
