import computingComponents from '../../assets/articles/computing-components.png';

export const Values = () => {
  return (
    <article className="textImageCard--variation textImageCard">
      <div className="textImageCard__text">
        <p className="textImageCard__paragraph">Nossa missão é utilizar a tecnologia de ponta para desenvolver
          soluções poderosas e ágeis que transformem o mundo dos negócios e melhorem a vida das pessoas.</p>
        <p className="textImageCard__paragraph">Nossa visão é ser líder em inovação e tecnologia, buscando
          constantemente novas formas de aplicar nossos conhecimentos para criar valor para nossos clientes e
          parceiros, e contribuir para um mundo mais conectado e sustentável.</p>
      </div>
      <figure className="textImageCard__image">
        <img src={computingComponents} alt="Componentes de Computador"/>
        <figcaption>Imagem de "Componentes de Computador"</figcaption>
      </figure>
    </article>
  );
};
