import './App.css';
import {Header, Main, Footer} from "./components";

const App = () => {
  return (
    <div className='App'>
      <Header/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
